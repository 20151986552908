import { TableHeaderBtn } from '@/App/Components/TableHeader/TableHeader'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { BaseOrganization } from '@/App/Services/interfaces/organization.interface'
import i18n from '@/plugins/i18n'

export const TABLE_HEADERS: VuetifyTableHeader[] = [
  { text: i18n.tc('organizationPage.constants.statusText'), align: 'left', sortable: true, value: 'isActive' },
  { text: i18n.tc('organizationPage.constants.nameText'), align: 'left', sortable: true, value: 'name' },
  { text: i18n.tc('organizationPage.constants.monitoringText'), align: 'left', sortable: true, value: 'maxMonitoringDashboards' },
  { text: i18n.tc('organizationPage.constants.insightText'), align: 'left', sortable: true, value: 'maxInsightDashboards' },
  { text: i18n.tc('organizationPage.constants.devicesText'), align: 'left', sortable: true, value: 'maxDevices' },
  { text: i18n.tc('organizationPage.constants.managersText'), align: 'left', sortable: true, value: 'maxManagers' },
  { text: i18n.tc('organizationPage.constants.operatorsText'), align: 'left', sortable: true, value: 'maxOperators' },
  { text: i18n.tc('organizationPage.constants.notificationText'), align: 'left', sortable: true, value: 'maxNotification' },
  { text: i18n.tc('organizationPage.constants.scheduleReportsText'), align: 'left', sortable: true, value: 'maxScheduleReports' },
  { text: i18n.tc('organizationPage.constants.widgetsText'), align: 'left', sortable: true, value: 'maxWidgets' },
  { text: i18n.tc('organizationPage.constants.geofencesText'), align: 'left', sortable: true, value: 'maxGeofences' },
  { text: i18n.tc('organizationPage.constants.smsLimitText'), align: 'left', sortable: true, value: 'smsLimit' },
  { text: i18n.tc('organizationPage.constants.actionText'), value: 'left', align: 'center', sortable: false }
]

export const TABLE_HEADER_BTN: TableHeaderBtn[] = [
    { icon: 'fa fa-plus', tooltip: i18n.tc('organizationPage.constants.toolTipText'), action: 'onOrganizationCreate' }
]

export const ORGANIZATION_FORM: BaseOrganization = {
  name: '',
  maxMonitoringDashboards: 0,
  maxInsightDashboards: 0,
  maxDevices: 0,
  maxManagers: 0,
  maxNotification: 0,
  maxOperators: 0,
  maxScheduleReports: 0,
  maxWidgets: 0,
  maxGeofences: 0,
  smsGateway: 'none',
  smsLimit: 0,
  useDeviceManagementModule: false,
  isCreateCoreToken: true
}
