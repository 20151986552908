import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { OrganizationService } from '@/App/Services/Organization.service'
import TableHeader from '@/App/Components/TableHeader/Table-Header.vue'
import OrganizationDialog from './OrganizationDialog/Organization-Dialog.vue'
import { TABLE_HEADER_BTN, TABLE_HEADERS } from '../constants'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { IOrganization } from '@/App/Services/interfaces/organization.interface'

const sharedStore = namespace('Shared')
const organizationStore = namespace('Organization')

@Component({
  components: { TableHeader, OrganizationDialog }
})
export default class OrganizationsTable extends Vue {
  $refs: {
    organizationDialog: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @sharedStore.Action
  private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

  @organizationStore.State
  public readonly organizations: IOrganization[]

  @organizationStore.Mutation
  private readonly updateOrganization: (organization: Partial<IOrganization>) => void

  @organizationStore.Mutation
  public readonly destroyOrganization: (id: string) => void

  @organizationStore.Action
  private readonly fetchOrganizations: () => Promise<void>

  public search = ''
  public isLoading = false
  public headers = TABLE_HEADERS
  public headerActionsBtn = TABLE_HEADER_BTN

  public onSearch(text: string): void {
    this.search = text
  }

  public openOrganizationDialog(): void {
    this.$refs.organizationDialog.open()
  }

  public editOrganization(organization: IOrganization): void {
    this.$refs.organizationDialog.edit(organization)
  }
  // TODO add update organization in store with new status
  public async statusChanged(id: string, status: boolean): Promise<void> {
    try {
      this.isLoading = true
      await OrganizationService.update(id, { isActive: status })

      this.setNotification({ text: this.$t('organizationPage.organizationsTable.organizationChangedNotificationText').toString() })
    } catch {} finally {
      this.isLoading = false
    }
  }

  public async deleteOrganization(id: string) {
    const title = this.$t('organizationPage.organizationsTable.deleteOrganizationConfirmTitle').toString()
    const text = `
        ${this.$t('organizationPage.organizationsTable.deleteOrganizationConfirmText1')}<br/>
        ${this.$t('organizationPage.organizationsTable.deleteOrganizationConfirmText2')}<br/>
        ${this.$t('organizationPage.organizationsTable.deleteOrganizationConfirmText3')}
    `

    if (await this.confirm({ title, text })) {
      try {
        this.isLoading = true
        this.destroyOrganization(await OrganizationService.destroy(id))

        this.setNotification({ text: this.$t('organizationPage.organizationsTable.organizationDeletedNotificationText').toString() })
      } catch {} finally {
        this.isLoading = false
      }
    }
  }

  public async dropSMSLimit(_id: string): Promise<void> {
    try {
      this.isLoading = true

      await OrganizationService.update(_id, { smsSent: 0 })

      this.updateOrganization({ _id, smsSent: 0 })

      this.setNotification({ text: this.$t('organizationPage.organizationsTable.limitsNotificationText').toString() })
    } catch {} finally {
      this.isLoading = false
    }
  }

  private async mounted(): Promise<void> {
    try {
      this.isLoading = true

      await this.fetchOrganizations()
    } catch {} finally {
      this.isLoading = false
    }
  }
}
