import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { OrganizationService } from '@/App/Services/Organization.service'
import { ORGANIZATION_FORM } from '@/App/Pages/Organization/constants'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { BaseOrganization, IOrganization } from '@/App/Services/interfaces/organization.interface'

const sharedStore = namespace('Shared')
const organizationStore = namespace('Organization')

@Component
export default class OrganizationDialog extends Mixins(Validator) {
  $refs: {
    form: HTMLFormElement
  }

  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  @organizationStore.Mutation
  private readonly addOrganization: (organization: IOrganization) => void

  @organizationStore.Mutation
  private readonly updateOrganization: (organization: Partial<IOrganization>) => void

  public isOpen = false
  public isLoading = false
  public form = false
  public id: string | null = null
  public organization: BaseOrganization = { ...ORGANIZATION_FORM }
  public smsGatewayItems: string[] = ['none', 'TextWare']

  public open(): void {
    this.isOpen = true
  }

  public close(): void {
    this.isOpen = false

    this.organization = { ...ORGANIZATION_FORM }
    this.id = null

    this.$refs.form.resetValidation()
  }

  public edit(organization: IOrganization): void {
    this.id = organization._id
    delete organization._id
    this.organization = organization

    this.open()
  }

  public async submit(): Promise<void> {
    if (this.id) {
      await this.update()
    } else {
      await this.store()
    }
  }

  public async store(): Promise<void> {
    try {
      this.isLoading = true

      this.addOrganization(await OrganizationService.store(this.organization))

      this.setNotification({ text: this.$t('organizationPage.organizationsTable.organizationDialog.organizationSavedNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }

  public async update(): Promise<void> {
    try {
      this.isLoading = true

      this.updateOrganization(await OrganizationService.update(this.id!, this.organization))

      this.setNotification({ text: this.$t('organizationPage.organizationsTable.organizationDialog.organizationUpdatedNotificationText').toString() })

      this.close()
    } catch {} finally {
      this.isLoading = false
    }
  }

  get title(): string {
    return this.id ? this.$t('organizationPage.organizationsTable.organizationDialog.editText').toString()
      : this.$t('organizationPage.organizationsTable.organizationDialog.creatingNewText').toString()
  }

  get buttonText(): string {
    return this.id ? this.$t('organizationPage.organizationsTable.organizationDialog.updateText').toString()
      : this.$t('organizationPage.organizationsTable.organizationDialog.saveText').toString()
  }
}
